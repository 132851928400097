import React from "react";
import { ButtonContainer, CenterText, DescriptionText, FadeStyled, InfoBackground, InfoGroup, MapViewContainer, SectionTitle, SubTitle } from "./styles";
import MapView from "../../../../components/MapView";
import { MainButton } from "../../../../components/MainButton";
import { OutlineButton } from "../../../../components/OutlineButton";
import { EndSectionLine } from "../../../../components/EndSectionLine";
import { GENERAL_ROUTES, HEADER_ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";

const PAROQUIA_LOCATION = { lat: -22.738004839330536, lng: -42.83858880181728 };
const RECEPCAO_LOCATION = { lat: -22.760448534535342, lng: -42.83468585980541 };

export const EventDetails = () => {

  const navigate = useNavigate();
  const openDirections = (location: { lat: number, lng: number }) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`;
    window.open(url, '_blank');
  }

  return (
    <InfoBackground id={HEADER_ROUTES.EVENT_DETAILS.id}>
      <FadeStyled>
        <SectionTitle>DETALHES DO EVENTO</SectionTitle>
        <InfoGroup>
          <SubTitle>Save the date</SubTitle>
          <DescriptionText>Sábado, 19 de Julho. As 19 horas.</DescriptionText>
        </InfoGroup>
        <InfoGroup>
          <SubTitle>Celebração</SubTitle>
          <DescriptionText>Paróquia São Pedro Apóstolo, Av. Vinte e Dois de Maio, 2280 - Venda das Pedras, Itaboraí - RJ, 24804-703</DescriptionText>
          <MapViewContainer>
            <MapView location={PAROQUIA_LOCATION} />
          </MapViewContainer>
          <ButtonContainer>
            <MainButton onClick={() => openDirections(PAROQUIA_LOCATION)}>
              ROTAS
            </MainButton>
          </ButtonContainer>
        </InfoGroup>
        <InfoGroup>
          <SubTitle>Recepção</SubTitle>
          <DescriptionText>Recanto Molina, RJ-114, 741 - lote 106 quadra 1 - Calundu, Itaboraí - RJ, 24800-000</DescriptionText>
          <MapViewContainer>
            <MapView location={RECEPCAO_LOCATION} />
          </MapViewContainer>
          <ButtonContainer>
            <MainButton onClick={() => openDirections(RECEPCAO_LOCATION)}>
              ROTAS
            </MainButton>
          </ButtonContainer>
        </InfoGroup>
        <InfoGroup>
          <SubTitle>Não tem onde ficar?</SubTitle>
          <DescriptionText>Verifique nossas recomendações de hotéis e reserve sua acomodação na região.</DescriptionText>
          <ButtonContainer>
            <OutlineButton onClick={() => navigate(GENERAL_ROUTES.HOTEL_RECOMMENDATIONS.route)} >
              Sugestões de acomodações
            </OutlineButton>
          </ButtonContainer>
        </InfoGroup>
        <br />
        <br id={HEADER_ROUTES.RSVP.id}/>
        <EndSectionLine color="terracota" />
        <SectionTitle marginTop={20}>CONFIRMAR PRESENÇA</SectionTitle>
        <CenterText>Para confirmar sua presença, basta escanear o código QR que está no seu convite ou acessar a página de confirmação abaixo.</CenterText>
        <InfoGroup>
          <ButtonContainer>
            <MainButton onClick={() => null}>
              CONFIRMAR PRESENÇA
            </MainButton>
          </ButtonContainer>
        </InfoGroup>
        <br />
        <br />
        <EndSectionLine color="terracota" />
      </FadeStyled>
    </InfoBackground>
  );
};