import React from "react";
import { Author, BibleSentence, ContainerBackground, DescriptionText, Spacer } from "./styles";
import { EndSectionLine } from "../../../../components/EndSectionLine";
import { RegressiveCounter } from "./components/RegrressiveCounter";


export const CoupleIntro = () => {

    return (
            <ContainerBackground>
                <Spacer flex={5}>
                    <BibleSentence>"PORTANTO, O QUE DEUS UNIU, NINGUÉM SEPARE”</BibleSentence>
                    <Author>Mateus 19:6</Author>
                    <DescriptionText>Este espaço foi criado especialmente para você, que é parte essencial da nossa história. Enquanto o grande dia não chega, aproveite para conhecer melhor a nossa jornada, conferir os detalhes sobre o local e horário da festa, acessar nossa lista de presentes e muito mais.</DescriptionText>
                    <DescriptionText>Agradecemos por compartilhar este momento tão importante conosco.</DescriptionText>
                    <DescriptionText width={150}>Com amor,<br></br> Mayara e João Victor.</DescriptionText>
                    <RegressiveCounter />
                </Spacer>
                <Spacer flex={1}>
                    <EndSectionLine color="white" />
                </Spacer>
            </ContainerBackground>
    )
};