import styled from "styled-components";
import { DEVICE } from "../../../../../../constants/ScreenSizes";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.palette.text};
    background-color: ${({ theme }) => theme.palette.primaryColor};
`;

export const Title = styled.h2`
    ${({ theme }) => theme.typography.secondaryTitle};
    margin-bottom: 15px;

    @media ${DEVICE.DESKTOP} {
        font-size: 1.2rem;
    }
`;

export const CounterContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 95%;
    max-width: 400px;
`;

export const ItemContainer = styled.div<WidthProps>`
    display: flex;
    flex-direction: column;
    height: 120px;
    width: ${({ smallItem }) => smallItem ? "3%" : "22.5%"};
`;

export const NumberItem = styled.h3<WidthProps>`
    ${({ theme }) => theme.typography.lightText};
    font-size: 40px;
    text-align: center;
    height: 80%;
    margin-top: 10px;
    margin-bottom: 0;
`;

export const DescriptionText = styled.p`
    ${({ theme }) => theme.typography.primaryTitle};
    font-size: 14px;
    width: 100%;
    text-align: center;
    height: 20%;
    margin-top: -30px;
`;

interface WidthProps {
    smallItem?: boolean;
}