import React from "react";
import { InvitedMessage } from "../../../../../../types/api/invitedMessage";
import { CarouselItemContainer, PostitImage, PostitWrapper } from "./styles";
import logoImage from "../../../../../../assets/images/postitImage.png";

interface MessageCarouselItemProps {
    message: InvitedMessage;
}

export const MessageCarouselItem = ({ message }: MessageCarouselItemProps) => {

    return (
        <CarouselItemContainer>
            <PostitImage src={logoImage} />
            <PostitWrapper>
                <h3>{message.text}</h3>
                <p>{message.name}</p>
            </PostitWrapper>
        </CarouselItemContainer>
    );
}