import { Fade } from "react-awesome-reveal";
import styled from "styled-components";
import { DEVICE } from "../../../../constants/ScreenSizes";

interface LogoProps {
  size: number;
}

export const ContainerBackground = styled.div`
  background-color: ${(props) => props.theme.palette.primaryColor};
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ theme }) => theme.typography.primaryText};
`;

export const ScrollContainer = styled.div`
    width: 100%;
    min-height: 300vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
`;

export const SvgContainer = styled.div<SyncContainerProps>`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${(props) => props.top}px;
    transition: top 0.5s;
    left: 0;
`;

interface SyncContainerProps {
  top: number;
}

export const Logo = styled.img<LogoProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  transition: width 0.5s, height 0.5s;
`;


export const InfoBackground = styled.div`
  background-color: ${(props) => props.theme.palette.text};
  width: 95%;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 32px;
  ${({ theme }) => theme.typography.primaryText};
`;

export const SectionTitle = styled.div<SectionTitleProps>`
    ${({ theme }) => theme.typography.primaryTitle};
    color: ${({ theme }) => theme.palette.tertiaryColorLight};
    text-align: center;
    margin-top: 50px;
    font-size: 1.5rem;
    margin-top: ${({ marginTop }) => marginTop ?? 120}px;
`;

interface SectionTitleProps {
  marginTop?: number;
}

export const SubTitle = styled.div`
    ${({ theme }) => theme.typography.secondaryText};
    color: ${({ theme }) => theme.palette.secondaryColor};
    font-size: 1rem;
    font-weight: bold;

    @media ${DEVICE.DESKTOP} {
        font-size: 1.2rem;
    }
`;


export const DescriptionText = styled.p`
    ${({ theme }) => theme.typography.lightText};
    color: ${({ theme }) => theme.palette.primaryColor};
    font-size: 1rem;
    margin: 0;

    @media ${DEVICE.DESKTOP} {
        font-size: 1.1rem;
    }
`;

export const FadeStyled = styled(Fade)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

export const InfoGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 700px;
    margin-top: 30px;
`;

export const MapViewContainer = styled.div`
    width: 100%;
    height: 300px;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
`;

export const CenterText = styled(DescriptionText)`
    text-align: center;
    margin-top: 20px;
    width: 90%;
    max-width: 700px;
`;