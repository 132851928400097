import styled from "styled-components";

export const CarouselItemContainer = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: ${({ theme }) => theme.palette.primaryColor};
`;

export const PostitWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;

    h3 {
        margin: 70px;
        ${({ theme }) => theme.typography.primaryTitle};
        color: ${({ theme }) => theme.palette.primaryColor};
        margin-bottom: -20px;
    }
`;

export const PostitImage = styled.img`
    width: 90%;
    height: 80%;
`;
