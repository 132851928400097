import { DefaultTheme } from "styled-components";

export const DarkTheme: DefaultTheme = {
    palette: {
        primaryColor: "#54582F",
        primaryColorLight: "#54582F99",
        secondaryColor: "#272B00",
        tertiaryColorLight: "#8D4A2A",
        tertiaryColorDark: "#5D2519",
        emptyGreen: "#BEC092",
        background: "#EFEFEF",
        text: "#F8FBCB",
        textSecondary: "#F8FBCB99",
        imageMask: "#54582FAA",
        error: "#ff4545",
    },
    typography: {
		primaryText: {
            fontFamily: "nice",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            color: "#F8FBCB99",
        },
        primaryTitle: {
            fontFamily: "nice",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            color: "#F8FBCB",
        },
        secondaryText: {
            fontFamily: "inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            color: "#F8FBCB99",
        },
        secondaryTitle: {
            fontFamily: "inter",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            color: "#F8FBCB",
        },
        lightText: {
            fontFamily: "inter-light",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            color: "#F8FBCB",
        },
    }
} 