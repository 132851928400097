import React from "react";
import { ContainerBackground, SectionTitle, TimeLineContainer } from "./styles";
import { CardItem, CardItemProps } from "./components/CardItem";
import { useWindowDimensions } from "../../../../hooks";
import image1 from "../../../../assets/images/ourHistory/image1.png";
import image2 from "../../../../assets/images/ourHistory/image2.png";
import image3 from "../../../../assets/images/ourHistory/image3.png";
import image4 from "../../../../assets/images/ourHistory/image4.png";
import { EndSectionLine } from "../../../../components/EndSectionLine";
import { HEADER_ROUTES } from "../../../../constants/routes";


export const OurHistory = () => {
  const { width } = useWindowDimensions();

  const ITEMS: CardItemProps[] = [
    {
      title: "O errado que deu certo",
      description: "O ano era 2014 e o casal de adolescentes João Victor e Mayara se conheciam em um retiro da paróquia São Pedro, em Venda das Pedras. Papo vai, papo vem, os dois sem juízos resolveram iniciar um namoro. Seja por timidez, por falta de maturidade ou por inúmeros outros motivos, esse namoro não durou muito tempo. Após poucos meses eles terminaram. Mas algo de bom sobrou daquele relacionamento.",
      image: image1,
    },
    {
      title: "O reencontro",
      description: "Anos depois, a amizade mantida pelos dois ganhou mais força e em 2018, com um pouco mais de idade (e maturidade) eles resolveram tentar novamente.",
      image: image2,
    },
    {
      title: "O namoro",
      description: "De lá pra cá, foram muitos momentos juntos, muitas histórias e risadas. A cada dia, a relação foi se fortalecendo, com aventuras compartilhadas, desafios e conquistas.",
      image: image3,
    },
    {
      title: "Juntando as escovas de dentes",
      description: "Após 6 anos de muito amor e cumplicidade, chegou o momento de juntar as escovas de dentes.",
      image: image4,
    },
    {
      title: "Continua...",
      description: undefined,
      image: undefined,
    }
  ];

  return (
    <ContainerBackground id={HEADER_ROUTES.OUR_HISTORY.id}>
      <SectionTitle>NOSSA HISTÓRIA</SectionTitle>
      <TimeLineContainer screenWidth={width}>
        {
          ITEMS.map((item, index) => (
            <CardItem
              key={index}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))
        }
      </TimeLineContainer>
      <EndSectionLine color="white" />
    </ContainerBackground>
  );
};